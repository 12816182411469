<script>
import axios from 'axios';
import {vm} from '@/main';
import i18n from '@/plugins/i18n';

axios.defaults.withCredentials = true;
const baseAPI = `${process.env.VUE_APP_URL}api/v0`;
const baseLOGIN = `${process.env.VUE_APP_URL}login/v0`;

const REST = axios.create({
  baseURL: baseAPI,
  withCredentials: true,
  headers: {
  //   'Access-Control-Allow-Credentials': 'true',
    Accept: 'application/json',
    // 'Content-Type': 'application/json'
  },
  xsrfCookieName: 'CSRF',
  xsrfHeaderName: 'X-CSRF',
  paramsSerializer: function(params) {
                      // maybe move to AXIOS object
                      let result = '';
                      Object.keys(params).forEach(key => {
                        if (key === 'q' || key === 'p' || key === 's') {
                          result += `${key}=${params[key]}&`; // skip encoding of the "q" and "p" param
                        // } else if (key === 's') {
                        //   result += `${key}=${params[key].substr(0,1)}${encodeURIComponent(params[key].substr(1))}&`;
                        } else {
                          result += `${key}=${encodeURIComponent(params[key])}&`;
                        }
                      });
                      return result.substr(0, result.length - 1);
                    }
})

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  })
  
  failedQueue = [];
}

REST.interceptors.response.use(response => {
  return {d: response.data, h: response.headers, c: response.status};
}, async function(error) {
  if (error.config.responseType === 'blob') { // re-interprete blob data as JSON
    const data = await new Promise((resolve, reject) => {
      let reader = new FileReader(); // ugly CB class
      reader.onload = () => { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsText(error.response.data);
    });
    // const data = await new Response(error.response.data).text(); // newer version using Blob.text()
    error.response.data = JSON.parse(data);
  }
  if (Object.prototype.hasOwnProperty.call(error, 'response') && error.response != null &&
      Object.prototype.hasOwnProperty.call(error.response, 'data') && error.response.data != null &&
      Object.prototype.hasOwnProperty.call(error.response.data, 'message') && error.response.data.message != null) {
    const origRequest = error.config;
    if (error.response.status === 401 && !origRequest._retry && !origRequest.url.includes(baseLOGIN.concat('/access')) /*&& vm.$store.state.oLogin.isLoggedIn*/) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({resolve, reject});
        }).then(() => {
          return REST(origRequest);
        }).catch(err => {
          return Promise.reject(err);
        })
      }
      origRequest._retry = true;
      isRefreshing = true;
      return new Promise(function(resolve, reject) {
        REST.post(baseLOGIN.concat('/access')
            ).then((data) => {
              if (data.c === 200 && data.h['x-uuid'].split('.')[0] === vm.$store.getters.uuid) {
                processQueue(null);
                resolve(REST(origRequest));
              } else {
                let err = {...error.response.data, code: error.response.status};
                processQueue(err);
                reject(err);
              }
            }).catch((err) => {
              processQueue(err);
              // default action if still logged in
              if (vm.$store.state.oLogin.isLoggedIn) {
                vm.$store.commit('logout');
                vm.$router.push('/').catch(() => {});
              }
              reject(err);
            })
          .then(() => {
            isRefreshing = false;
          });
      });
    }
    if (error.response.status === 401 && origRequest.url.includes(baseLOGIN.concat('/access')) /*&& vm.$store.state.oLogin.isLoggedIn*/) {
      vm.$store.commit('logout');
      vm.$router.push('/').catch(() => {});
      error.response.data.message = i18n.t('rc.1');
    }
    return Promise.reject({...error.response.data, code: error.response.status});
  } else {
    let obj = { type: 'error', message: i18n.t('rc.2') };
    return Promise.reject(obj);
  }
});

export { REST, baseLOGIN, axios };

export default {};

</script>
