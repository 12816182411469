<template>
  <CookieCheckContent v-if="needsCheck && isExpired()" />
</template>

<script>
export default {
  computed: {
    needsCheck() {
      return this.$store.state.cookieCheck === true;
    },
  },
  methods: {
    isExpired() {
      if (this.$store.state.settings.cookieCfg.ts === null) return true;
      const sixMonthsAgo = new Date(); // roughly
      sixMonthsAgo.setDate(sixMonthsAgo.getDate() - 180);
      return (sixMonthsAgo > new Date(this.$store.state.settings.cookieCfg.ts));
    },
  },
  components: {
    CookieCheckContent: () => import('@/components/scripts/CookieCheckContent'),
  },
}
</script>
