<template>
  <component :is="getType" v-bind="getBind" />
</template>

<script>
import {  BIconSquare, BIconClock, BIconClockHistory, BIconTextareaT, BIconArrowCounterclockwise, BIconUpload, BIconPencilSquare, BIconArrowRepeat, BIconArrowReturnRight,
          BIconArrowRightSquare, BIconArrowLeftSquare, BIconXSquare, BIconCalendar2Check, BIconCreditCard, BIconSearch, BIconDashSquare, BIconPlusSquare, BIconSlashSquare, BIconCheckSquare, BIconQuestionSquare, BIconExclamationSquare,
          BIconMap, BIconStar, BIconLock, BIconBoxArrowInRight, BIconBoxArrowLeft, BIconCardChecklist, BIconUpcScan,
          BIconCircle, BIconXCircle, BIconSlashCircle, BIconCheckCircle, BIconPlusCircle, BIconDashCircle, BIconQuestionCircle, BIconExclamationCircle,
          BIconDownload, BIconLink } from 'bootstrap-vue';

const list = {
  'z':    'BIconSquare',
  'l':    'BIconTextareaT',
  't':    'BIconClock',
  'tx':   'BIconClockHistory',
  'r':    'BIconArrowCounterclockwise',
  'u':    'BIconUpload',
  'e':    'BIconPencilSquare',
  'a':    'BIconArrowLeftSquare',
  'f':    'BIconArrowRepeat',
  'p':    'BIconArrowReturnRight',
  'm':    'BIconArrowRightSquare',
  'b':    'BIconArrowLeftSquare',
  'x':    'BIconXSquare',
  'res':  'BIconCalendar2Check',
  'sea':  'BIconSearch',
  'tra':  'BIconCreditCard',
  'add':  'BIconPlusSquare',
  'del':  'BIconDashSquare',
  'rej':  'BIconSlashSquare',
  'acc':  'BIconCheckSquare',
  'can':  'BIconXSquare',
  'shw':  'BIconSearch',
  'cnf':  'BIconPlusSquare',
  'dis':  'BIconQuestionSquare',
  'prt':  'BIconExclamationSquare',
  'rsv':  'BIconDashSquare',
  'sub':  'BIconCheckSquare',
  'map':  'BIconMap',
  'fav':  'BIconStar',
  'lgi':  'BIconBoxArrowInRight',
  'lgo':  'BIconBoxArrowLeft',
  'lrg':  'BIconCardChecklist',
  'qr':   'BIconUpcScan',
  'dl':   'BIconDownload',
  'ln':   'BIconLink',
  'CREATED':  'BIconCircle',
  'CANCELED': 'BIconXCircle',
  'REJECTED': 'BIconSlashCircle',
  'ACCEPTED': 'BIconCheckCircle',
  'LOCKED':   'BIconLock',
  'SHOWED':   'BIconPlusCircle',
  'NOSHOWED': 'BIconDashCircle',
  'DISPUTED': 'BIconQuestionCircle',
  'ESCALATED':'BIconExclamationCircle',
  'PREPARED': 'BIconCircle',
  'COMPLETED':'BIconPlusCircle',
  'ABORTED':  'BIconDashCircle',
  'PLACED':   'BIconLock',
  'INVESTIGATED': 'BIconExclamationCircle',
  'CLEANED':  'BIconExclamationCircle',
};

export default {
  computed: {
    getType() {
      if (!(this.type in list)) return list['z'];
      return list[this.type];
    },
    getBind() {
      if (this.isButton && !this.isNav && !this.isText) {
        return this.hasNoText ? this.iBS : this.iB;
      }
      if (!this.isButton && this.isNav && !this.isText) {
        return this.iH;
      }
      if (!this.isButton && !this.isNav && this.isText) {
        return this.iT;
      }
      return {};
    },
    iB() {
      return this.$store.getters.iB;
    },
    iBS() {
      return this.$store.getters.iBS;
    },
    iT() {
      return this.$store.getters.iT;
    },
    iH() {
      return this.$store.getters.iH;
    },
  },
  props: {
    type: {
      type: String,
      default: 'BIconSquare',
    },
    isButton: {
      type: Boolean,
      default: true,
    },
    hasNoText: {
      type: Boolean,
      default: false,
    },
    isNav: {
      type: Boolean,
      default: false,
    },
    isText: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BIconSquare,
    BIconClock,
    BIconClockHistory,
    BIconTextareaT,
    BIconArrowCounterclockwise,
    BIconUpload,
    BIconPencilSquare,
    BIconArrowRepeat,
    BIconArrowRightSquare,
    BIconArrowLeftSquare,
    BIconArrowReturnRight,
    BIconXSquare,
    BIconCalendar2Check,
    BIconCreditCard,
    BIconSearch,
    BIconPlusSquare,
    BIconDashSquare,
    BIconSlashSquare,
    BIconCheckSquare,
    BIconQuestionSquare,
    BIconExclamationSquare,
    BIconMap,
    BIconStar,
    BIconLock,
    BIconBoxArrowInRight,
    BIconBoxArrowLeft,
    BIconCardChecklist,
    BIconUpcScan,
    BIconCircle,
    BIconXCircle,
    BIconSlashCircle,
    BIconCheckCircle,
    BIconPlusCircle,
    BIconDashCircle,
    BIconQuestionCircle,
    BIconExclamationCircle,
    BIconDownload,
    BIconLink,
  }
}
</script>
