import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

// here, we should only include the base for all pages and components
//  and defer the special things to CSS/SCSS/JS sections in the SFA components

// import './custom.scss'

Vue.use(BootstrapVue)
