import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
// import * as defMsg from '@/lang/en-US.json';
import langSet from '@/components/data/Languages';

Vue.use(VueI18n);

const numberFormats = {
  'en-US': {
    c: {
      style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2,
    },
    p: {
      style: 'percent'
    },
    d: {
      style: 'decimal'
    }
  },
  'de-DE': {
    c: {
      style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2,
    },
    p: {
      style: 'percent'
    },
    d: {
      style: 'decimal'
    }
  }
}
const dateTimeFormats = {
  'en-US': {
    swd: 0,
    dir: 'ltr',
    do: {
      month: '2-digit',
      day: '2-digit'
    },
    ds: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    dl: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long'
    },
    as: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    },
    al: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    },
    t: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
  },
  'de-DE': {
    swd: 1,
    dir: 'ltr',
    do: {
      month: '2-digit',
      day: '2-digit'
    },
    ds: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    dl: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'long'
    },
    as: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric'
    },
    al: {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    },
    t: {
      hour: 'numeric',
      minute: 'numeric'
    }
  }
}

const i18n = new VueI18n({
  locale: 'off',
  fallbackLocale: langSet[0],
  messages: {},
  dateTimeFormats,
  numberFormats
});

const loadedLangs = {};

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);

  return Promise.resolve(lang);
}

export function loadLangAsync(lang, moduleArr, oLogin) {
  let userString = '';
  if (oLogin != null) { // use store
    // switch between cust and user roles not supported
    userString = oLogin.isLoggedIn ? (oLogin.isUser ? '_u' : '_c') : '';
  }
  if (lang == null) {
    lang = 0; // select first as default
  }
  if (typeof lang !== 'number' && typeof lang !== 'string') {
    lang = 0;
  }
  if (typeof lang === 'number') {
    lang = langSet[lang]; // transform into string
  }
  if (moduleArr == null || moduleArr.length == 0)  {
    return Promise.resolve(true);
  } else if (typeof moduleArr === 'string') {
    moduleArr = [moduleArr];
  }
  if (!Array.isArray(moduleArr)) {
    throw new Error('Wrong lang param format');
  }

  moduleArr.forEach((e, i, a) => {
    a[i] = e + userString;
  });

  let toLoad = [];
  if (loadedLangs[lang] != null) {
    moduleArr.forEach((e) => {
      if (!loadedLangs[lang].includes(e)) {
        toLoad.push(e);
      }
    });
  } else {
    loadedLangs[lang] = [];
    toLoad = moduleArr;
  }
  // language module(s) already loaded
  if (toLoad.length == 0) {
    return setI18nLanguage(lang);
  }

  // load language module(s)
  return Promise.all(toLoad.map(function (fname) {
    return import(/* webpackChunkName: "[request]" */ `@/lang/${lang}_${fname}.json`);
  })).then((data) => {
    let messages = {};
    Object.assign(messages, ...data);
    delete messages.default
    Object.assign(messages, i18n.getLocaleMessage(lang));
    i18n.setLocaleMessage(lang, messages);
    loadedLangs[lang] = loadedLangs[lang].concat(toLoad);
    return setI18nLanguage(lang);
  }).catch((e) => {
    // unsupported language or non-existent file: use fallback
    throw new Error(`Lang (${lang}) file ${toLoad} not found: ${e.message}`);
  });
}

export function switchLangAsync(oldLang, newLang) {
  if (oldLang == null) {
    oldLang = 0; // select first as default
  }
  if (typeof oldLang === 'number') {
    oldLang = langSet[oldLang]; // transform into string
  }
  let list = [];
  if (loadedLangs[oldLang] == null) {
    list = ['main'];
  } else {
    list = loadedLangs[oldLang];
  }
  // may load unnecessarily many files
  return loadLangAsync(newLang, list, null);
}

export default i18n;
