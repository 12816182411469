<template>
  <div class="AnuNavtop">
    <b-navbar type="dark" variant="transparent" fixed="top" class="d-none d-sm-block anufontsmall" style="z-index:1035;">
      <b-navbar-nav class="text-nowrap justify-content-end">
        <b-nav-item to="/contact" link-classes="pt-1 pb-0"><b-icon-envelope v-bind="icon" /> {{ $t('nav.cnt') }}</b-nav-item>
        <NavTimezone />
        <NavLanguage />
      </b-navbar-nav>
    </b-navbar>
    <b-navbar toggleable="sm" type="dark" variant="white" fixed="top" class="ml-n2 align-items-end">
      <b-navbar-brand to="/" class="align-items-start">
        <b-img src="@/assets/logo.png" :alt="$t('nav.brand')" />
      </b-navbar-brand>
      <b-navbar-toggle target="nbt">
        <span class="navbar-toggler-icon" />
      </b-navbar-toggle>
      <b-collapse is-nav id="nbt">
        <b-navbar-nav class="ml-auto mt-sm-2 pt-sm-1 mb-sm-n1 px-4 px-sm-0 text-nowrap text-left border border-sm-0 rounded border-primary">
          <b-nav-item to="/"><b-icon-house v-bind="icon" /><span class="ml-1">{{ $t('nav.home') }}</span></b-nav-item>
          <b-nav-item to="/" v-if="!isLoggedIn" disabled><b-icon-info-circle v-bind="icon" /><span class="ml-1">{{ $t('nav.about') }}</span></b-nav-item>
          <b-nav-item to="/" disabled><b-icon-question-circle v-bind="icon" /><span class="ml-1">{{ $t('nav.help') }}</span></b-nav-item>

          <b-nav-item v-if="!isLoggedIn" v-b-modal.sgnWindowU @click="loadSignup=true"><Icon type="lrg" />{{ $t('nav.sgup') }}</b-nav-item>
          <b-nav-item v-if="!isLoggedIn" v-b-modal.loginWindow @click="loadLogin=true"><Icon type="lgi" />{{ $t('nav.lgin') }}</b-nav-item>

          <b-nav-item-dropdown v-if="isLoggedIn" id="nbtReservations" right menu-class="text-nowrap text-left">
            <template #button-content>
              <Icon type="res" isNav :isButton="false" /><span class="ml-1">{{ $t('nav.res.t') }}</span>
            </template>
            <b-dd-item to="/reservations/new" v-if="isUser"><b-icon-calendar2-plus v-bind="icon" /><span class="ml-2">{{ $t('nav.res.new') }}</span></b-dd-item>
            <b-dd-item to="/reservations" v-if="isUser"><b-icon-calendar3 v-bind="icon" /><span class="ml-2">{{ $t('nav.res.man') }}</span></b-dd-item>

            <b-dd-item to="/reservations/today" v-if="!isUser"><b-icon-calendar v-bind="icon" /><span class="ml-2">{{ $t('nav.res.tod') }}</span></b-dd-item>
            <b-dropdown-divider v-if="!isUser" />
            <b-dd-item to="/reservations" v-if="!isUser"><b-icon-calendar3 v-bind="icon" /><span class="ml-2">{{ $t('nav.res.man') }}</span></b-dd-item>
            <b-dd-item to="/reservations#block" v-if="!isUser"><b-icon-calendar2-plus v-bind="icon" /><span class="ml-2">{{ $t('nav.res.blk') }}</span></b-dd-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown v-if="isLoggedIn" id="nbtTransactions" right menu-class="text-nowrap text-left">
            <template #button-content>
              <b-icon-credit-card v-bind="icon" /><span class="ml-1">{{ $t('nav.tra.t') }}</span>
            </template>
            <b-dd-item to="/transactions/accounts"><b-icon-calculator v-bind="icon" /><span class="ml-2">{{ $t('nav.tra.acc') }}</span></b-dd-item>
            <b-dd-item to="/transactions/invoices"><b-icon-receipt v-bind="icon" /><span class="ml-2">{{ $t('nav.tra.inv') }}</span></b-dd-item>
            <b-dd-item to="/transactions"><b-icon-card-list v-bind="icon" /><span class="ml-2">{{ $t('nav.tra.show') }}</span></b-dd-item>
            <b-dd-item to="/transactions/reservations"><b-icon-calendar-check v-bind="icon" /><span class="ml-2">{{ $t(isUser ? 'nav.tra.dep' : 'nav.tra.cmp') }}</span></b-dd-item>
            <!-- <b-dd-item v-if="!isUser" href="#"><b-icon-inboxes v-bind="icon" /><span class="ml-2">{{ $t('nav.tra.red') }}</span></b-dd-item> -->
          </b-nav-item-dropdown>

          <b-nav-item-dropdown v-if="isLoggedIn" id="nbtAccount" right menu-class="text-nowrap text-left">
            <template #button-content>
              <b-icon-person-circle v-bind="icon" /><span class="ml-1">{{ $t('nav.acc.t') }}</span>
            </template>
            <b-dd-item to="/account"><b-icon-person-lines-fill v-bind="icon" /><span class="ml-2">{{ $t('nav.acc.uc') }}</span></b-dd-item>
            <b-dd-item to="/account/settings"><b-icon-tools v-bind="icon" /><span class="ml-2">{{ $t('nav.acc.sett') }}</span></b-dd-item>
            <b-dd-item to="/account/address"><b-icon-house-door v-bind="icon" /><span class="ml-2">{{ $t('nav.acc.add') }}</span></b-dd-item>

            <b-dd-item to="/account/favorites" v-if="isUser"><b-icon-star v-bind="icon" /><span class="ml-2">{{ $t('nav.acc.fav') }}</span></b-dd-item>

            <b-dd-item to="/account/restaurant" v-if="!isUser"><b-icon-shop v-bind="icon" /><span class="ml-2">{{ $t('nav.acc.rest') }}</span></b-dd-item>
            <b-dd-item to="/account/seats" v-if="!isUser"><b-icon-grid v-bind="icon" /><span class="ml-2">{{ $t('nav.acc.seat') }}</span></b-dd-item>
            <b-dd-item to="/account/tables" v-if="!isUser"><b-icon-collection v-bind="icon" /><span class="ml-2">{{ $t('nav.acc.tabl') }}</span></b-dd-item>
            <!-- <b-dropdown-divider v-if="!isUser"/>
            <b-dd-item to="/account/optional" v-if="!isUser">{{ $t('nav.acc.opt') }}</b-dd-item> -->

            <b-dropdown-divider />
            <b-dd-item v-b-modal.logoutWindow @click="loadLogout=true"><Icon type="lgo" />{{ $t('nav.lgout') }}</b-dd-item>
          </b-nav-item-dropdown>
          <b-dropdown-divider class="d-sm-none mx-n4" />
          <NavLanguage class="d-sm-none pb-2" />
          <NavTimezone class="d-sm-none pb-2" />
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <ModalLogin v-if="(!isLoggedIn||wasLoggedIn) && loadLogin" @hook:mounted="modalLoad('loginWindow')" @submit="loadLogin=false" @reset="loadLogin=false" @pwreset="showPwReset" />
    <ModalSignupUser v-if="(!isLoggedIn||wasLoggedIn) && loadSignup" @hook:mounted="modalLoad('sgnWindowU')" @submit="loadSignup=false" @reset="loadSignup=false" />
    <ModalPwReset v-if="(!isLoggedIn||wasLoggedIn) && loadPwReset" @hook:mounted="modalLoad('pwrWindow')" @submit="loadPwReset=false" @reset="loadPwReset=false" />
    <ModalLogout v-if="isLoggedIn && loadLogout" @hook:mounted="modalLoad('logoutWindow')" @submit="loadLogout=false" @reset="loadLogout=false" />
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import {  BIconHouse, BIconInfoCircle, BIconQuestionCircle, BIconEnvelope,
          BIconPersonCircle, BIconTools, BIconCalendar2Plus, BIconCalendar3, BIconCalendar,
          BIconCalculator, BIconReceipt, BIconCreditCard, BIconCalendarCheck,
          BIconCardList, BIconPersonLinesFill, BIconStar, BIconHouseDoor, BIconShop, BIconGrid, BIconCollection } from 'bootstrap-vue';

export default {
  data() {
    return  {
      loadLogin: false,
      loadSignup: false,
      loadPwReset: false,
      loadLogout: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    wasLoggedIn() {
      return this.$store.state.oLogin.wasLoggedIn;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    icon() {
      return this.$store.getters.iH;
    },
    storeShowLogin() {
      return this.$store.state.iface.showLogin;
    },
    storeShowSignup() {
      return this.$store.state.iface.showSignup;
    },
  },
  watch: {
    storeShowLogin: function (newV) {
      if (newV === true) {
        this.loadLogin = true;
        this.modalLoad('loginWindow');
        this.$store.commit('showLogin', false);
      }
    },
    storeShowSignup: function (newV) {
      if (newV === true) {
        this.loadSignup = true;
        this.modalLoad('sgnWindowU');
        this.$store.commit('showSignup', false);
      }
    },
  },
  methods: {
    modalLoad(name) {
      this.$bvModal.show(name); // fix for lazyloading modals
    },
    showPwReset() {
      this.loadPwReset = true;
      this.$bvModal.show('pwrWindow');
    },
    showLogin() {
      this.loadLogin = true;
    },
    showSignup() {
      this.loadSignup = true;
    },
  },
  components: {
    ModalLogin: () => import('@/components/navigation/ModalLogin'),
    ModalSignupUser: () => import('@/components/navigation/ModalSignupUser'),
    ModalPwReset: () => import('@/components/navigation/ModalPwReset'),
    ModalLogout: () => import('@/components/navigation/ModalLogout'),
    NavLanguage: () => import('@/components/navigation/NavLanguage'),
    NavTimezone: () => import('@/components/navigation/NavTimezone'),
    Icon,
    BIconHouse,
    BIconInfoCircle,
    BIconQuestionCircle,
    BIconEnvelope,
    BIconPersonCircle,
    BIconCreditCard,
    BIconCalendarCheck,
    BIconCalculator,
    BIconReceipt,
    BIconTools,
    BIconCalendar2Plus,
    BIconCalendar3,
    BIconCalendar,
    BIconCardList,
    // BIconInboxes,
    BIconPersonLinesFill,
    BIconStar,
    BIconHouseDoor,
    BIconShop,
    BIconGrid,
    BIconCollection,
  }
}
</script>

<style scoped>
  img {
    min-width: 90px;
    max-width: 110px;
    margin-bottom: 0;
    width:100% !important;
  }
  @media only screen and (min-width: 576px) {
    img {
      max-width: 300px;
      margin-bottom: -10%;
    }
  }
  .anufontsmall {
    font-size: 75% !important;
  }
</style>
