<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { REST } from '@/components/RestCall';
import Query from '@/components/RestQuery';

export default {
  data() {
    return {
      id : null,
    };
  },
  created() {
    if (this.isLoggedIn) {
      this.startBgCheck(this.isUser);
    }
  },
  beforeDestroy() {
    this.stopBgCheck();
  },
  watch: {
    isLoggedIn: function(newV, oldV) {
      if (newV === true && oldV === false) {
        this.startBgCheck(this.isUser);
      } else if (newV === false && oldV === true) {
        this.stopBgCheck();
      }
    }
  },
  methods: {
    startBgCheck() {
      if (this.id == null) {
        if (this.bgcTs == null) {
          this.$store.commit('setBgcTs', decodeURIComponent(Query.replaceMagicString('{N-1}')));
        }
        this.executeAction(); // also once immediately
        this.id = setInterval(() => {
          this.executeAction();
        }, 5 * 60 * 1000);
      }
    },
    stopBgCheck() {
      if (this.id != null) {
        clearInterval(this.id);
      }
    },
    executeAction() {
      if (this.isUser) {
        this.fetchTransactions();
      } else {
        this.fetchReservations();
      }
    },
    fetchTransactions() {
      let query = Query.setFilter({p: '1'}, [['type','','PAYMENT'],['state','','PREPARED'],['createdAt','ge',this.bgcTs]]);
      let now = new Date().toISOString().substring(0, 16).replace('T',' ');
      REST.get('/users/' + this.$store.getters.uuid + '/transactions', { params: query }
        ).then(() => {
          // new trans exists
          this.$store.commit('showMessage', { message: this.$t('if.ntt'), title: this.$t('if.nth'), ts: now });
        }).catch(e => {
          // catch 404 warning: there may be nothing
          if (e.code == 404) {
            this.$store.commit('setBgcTs', now);
          } else {
            this.$store.commit('showWarn',e.message);
          }
        })
    },
    fetchReservations() {
      let query = Query.setFilter({p: '1'}, [['state','','CREATED'],['createdAt','ge',this.bgcTs]]);
      let now = new Date().toISOString().substring(0, 16).replace('T',' ');
      REST.get('/customers/' + this.$store.getters.uuid + '/reservations', { params: query }
        ).then(() => {
          // new res exists
          this.$store.commit('showMessage', { message: this.$t('if.nrt'), title: this.$t('if.nrh'), ts: now });
        }).catch(e => {
          // catch 404 warning: there may be nothing
          if (e.code == 404) {
            this.$store.commit('setBgcTs', now);
          } else {
            this.$store.commit('showWarn',e.message);
          }
        })
    }
  },
  computed: {
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    bgcTs() {
      return this.$store.state.settings.bgcTs;
    },
  },
  render() { // renderless
    return [];
  },
}

</script>
