import '@babel/polyfill'
//import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrapvue'
import './plugins/leaflet'
//import './plugins/cookies'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n, { loadLangAsync } from './plugins/i18n'
// import './plugins/scripts'
// import './plugins/directives'

Vue.config.productionTip = false

import { preauth } from './plugins/preauth'

let vm = null;

preauth().then((data) => {
  store.commit('initSettings');
  store.commit('login', data/*[0]*/);
  return loadLangAsync(store.state.settings.languageId, ['main'], null);
}).then(() => {
  vm = new Vue({
    store,//: data,
    router,
    i18n,
    render: h => h(App),
  });
  // delay mounting so store has definitely oLogin info, 
  //  as other rendered elements may fire off HTTP requests already
  vm.$nextTick(() => { vm.$mount('#app'); });
});

export { vm };
