import i18n from '@/plugins/i18n';

export default function(store) {
  store.subscribe(function(mutation, ) {
    if (mutation.type === 'showSuccess' || mutation.type === 'showWarn') {
      let isSucc = mutation.type === 'showSuccess';
      store._vm.$bvToast.toast(mutation.payload, {
        title: isSucc ? i18n.t('if.s') : i18n.t('if.w'),
        variant: isSucc ? 'primary' : 'danger',
        solid: true,
        autoHideDelay: 3000,
        appendToast: false,
      });
    } else if (mutation.type === 'showMessage') {
      const e = store._vm.$createElement;
      const arrContent = [];
      if (mutation.payload.link != null) {
        // only path location objects work as "to", not named routes. router causes a full page reload
        arrContent.push(e('b-link', { props: { to: mutation.payload.link }, scopedSlots: { default: function () { return e('span', mutation.payload.message ) }}}));
      } else {
        arrContent.push(mutation.payload.message);
      }
      const vNodesMsg = e(
        'p', { class: ['text-left'] },
        arrContent,
      );
      store._vm.$bvToast.toast([vNodesMsg], {
        title: mutation.payload.title,
        variant: 'primary',
        solid: true,
        autoHideDelay: 150000,
        appendToast: false,
        toaster: 'b-toaster-bottom-right',
      });
    }
  });
}
