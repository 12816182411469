import Vue from 'vue';
import VueRouter from 'vue-router';
import { loadLangAsync } from '@/plugins/i18n';
import store from '@/store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'AnuHome',
    component: () => import('@/views/Home'),
    meta: {
      langGen: ['home', 'transMain'],
      langSpec: ['home', 'res']
    }
  },
  {
    path: '/contact',
    name: 'AnuContact',
    component: () => import('@/views/Contact'),
    meta: {
      langGen: ['contact'],
      langSpec: null
    }
  },
  {
    path: '/imprint',
    name: 'AnuImprint',
    component: () => import('@/views/Imprint'),
    meta: {
      langGen: ['imprint'],
      langSpec: null
    }
  },
  {
    path: '/account',
    component: () => import('@/views/Account'),
    children: [
      { path: '', name: 'AnuAccount', component: () => import('@/components/account/Login') },
      { path: 'login', redirect: {name: 'AnuAccount'} },
      { path: 'settings', component: () => import('@/components/account/Settings') },
      { path: 'favorites', component: () => import('@/components/account/UserFavorites') },
      { path: 'address', component: () => import('@/components/account/Address'), meta: { langGen: ['address'] } },
      { path: 'restaurant', component: () => import('@/components/account/CustRestaurant'), meta: { langGen: ['address'] } },
      { path: 'seats', component: () => import('@/components/account/CustSeats') },
      { path: 'tables', component: () => import('@/components/account/CustTables') },
      { path: 'optional', component: () => import('@/components/account/CustOptional') }
    ],
    meta: {
      langGen: null,
      langSpec: ['account']
    }
  },
  {
    path: '/reservations',
    components: {
      default: () => import('@/views/Reservations'),
      secondary: () => import('@/components/reservations/Block')
    },
    children: [
      { path: '', name: 'AnuReservation', component: () => import('@/components/reservations/Next') },
      { path: 'next', redirect: { name: 'AnuReservation'} },
      { path: 'canceled', component: () => import('@/components/reservations/Canceled') },
      { path: 'previous', component: () => import('@/components/reservations/Previous') },
      { path: 'new', component: () => import('@/components/reservations/New'), meta: { langGen: ['trans', 'transMain'] } },
      { path: 'today', component: () => import('@/components/reservations/Today') }//,
      // { path: '*', redirect: {name: 'AnuReservation'} }
    ],
    meta: {
      langGen: null,
      langSpec: ['res']
    }
  },
  {
    path: '/reservations/:uuid([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})',
    component: () => import('@/views/ReservationsPage'),
    children: [
      { path: '', name: 'AnuReservationPage', component: () => import('@/components/reservations/Details'), meta: { langGen: ['trans', 'transMain'] } },
      { path: 'edit', name: 'AnuReservationEdit', component: () => import('@/components/reservations/Edit') },
      { path: 'restaurant', name: 'AnuReservationRest', component: () => import('@/components/shared/Restaurant'), meta: { langGen: ['address'], isReservation: true, } }
    ],
    meta: {
      langGen: null,
      langSpec: ['res'],
    }
  },
  {
    path: '/transactions',
    component: () => import('@/views/Transactions'),
    children: [
      { path: '', redirect: { name: 'AnuTransaction'}},
      { path: 'accounts', redirect: { name: 'AnuTransaction'}},
      { path: 'invoices', redirect: { name: 'AnuInvoices'}},
      { path: 'reservations', redirect: { name: 'AnuTransRes'}},
      { path: 'invoices_new', meta: { t: 'invoices', m: 'new'}, components: {
        mid: () => import('@/components/transactions/New'),
        top: () => import('@/components/transactions/Invoices') }},
      { path: 'invoices_current', name: 'AnuInvoices', meta: { t: 'invoices', m: 'current'}, components: {
        mid: () => import('@/components/transactions/Current'),
        top: () => import('@/components/transactions/Invoices') }},
      { path: 'invoices_previous', meta: { t: 'invoices', m: 'previous'}, components: {
        mid: () => import('@/components/transactions/Previous'),
        top: () => import('@/components/transactions/Invoices') }},
      { path: 'invoices_res', meta: { t: 'invoices', m: 'res'}, components: {
        mid: () => import('@/components/transactions/Res'),
        top: () => import('@/components/transactions/Invoices') }},
      { path: 'invoices_extra', meta: { t: 'invoices', m: 'extra'}, components: {
        mid: () => import('@/components/transactions/Extra'),
        top: () => import('@/components/transactions/Invoices') }},
      { path: 'accounts_new', meta: { t: 'accounts', m: 'new'}, components: {
        mid: () => import('@/components/transactions/New'),
        top: () => import('@/components/transactions/Accounts') }},
      { path: 'accounts_current', name: 'AnuTransaction', meta: { t: 'accounts', m: 'current'}, components: {
        mid: () => import('@/components/transactions/Current'),
        top: () => import('@/components/transactions/Accounts') }},
      { path: 'accounts_previous', meta: { t: 'accounts', m: 'previous'}, components: {
        mid: () => import('@/components/transactions/Previous'),
        top: () => import('@/components/transactions/Accounts') }},
      { path: 'accounts_res', name: 'AnuTransRes', meta: { t: 'accounts', m: 'res'}, components: {
        mid: () => import('@/components/transactions/Res'),
        top: () => import('@/components/transactions/Accounts') }},
      { path: 'accounts_extra', meta: { t: 'accounts', m: 'extra'}, components: {
        mid: () => import('@/components/transactions/Extra'),
        top: () => import('@/components/transactions/Accounts') }},
      // { path: '*', redirect: {name: 'AnuTransaction'} }
    ],
    meta: {
      langGen: ['trans', 'transMain'],
      langSpec: null,
    }
  },
  {
    path: '/transactions/:uuid([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})',
    component: () => import('@/views/TransactionsPage'),
    children: [
      { path: '', name: 'AnuTransactionPage', component: () => import('@/components/transactions/Details') },
      { path: 'restaurant', name: 'AnuTransactionRest', component: () => import('@/components/shared/Restaurant'), meta: { langGen: ['address'], isReservation: false, } }
    ],
    meta: {
      langGen: ['trans', 'transMain'],
      langSpec: null,
    }
  },
  {
    path: '/pwreset/:uuid([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})',
    component: () => import('@/views/ResetPasswordPage'),
    name: 'AnuPwreset',
    meta: {
      langGen: null,
      langSpec: null,
    }
  },
  {
    path: '/confirmcust/:uuid([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})',
    component: () => import('@/views/ConfirmPage'),
    name: 'AnuConfirm',
    meta: {
      isUser: false,
      langGen: ['address'],
      langSpec: null,
    }
  },
  {
    path: '/confirmuser/:uuid([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})',
    component: () => import('@/views/ConfirmPage'),
    name: 'AnuConfirm',
    meta: {
      isUser: true,
      langGen: ['address'],
      langSpec: null,
    }
  },
  {
    path: '/tos',
    name: 'AnuTos',
    component: () => import('@/views/Tos'),
    meta: {
      langGen: ['tos'],
      langSpec: null,
    }
  },
  {
    path: '/contentpolicy',
    name: 'AnuContentpolicy',
    component: () => import('@/views/ContentPolicy'),
    meta: {
      langGen: ['copo'],
      langSpec: null,
    }
  },
  {
    path: '/register',
    name: 'AnuHomecust',
    component: () => import('@/views/HomeCustomer'),
    meta: {
      langGen: ['homecust'],
      langSpec: null,
    }
  },
  {
    path: '/toscust',
    name: 'AnuTosCust',
    component: () => import('@/views/TosCustomer'),
    meta: {
      langGen: ['toscust'],
      langSpec: null,
    }
  },
  {
    path: '*',
    redirect: {name: 'AnuHome'}
  }
];

export async function loadLang(to, langId, oLogin) {
  if (to.matched.some(function (m) { return m.meta != undefined && (m.meta.langGen != undefined || m.meta.langSpec != undefined); })) {
    const langGen = to.matched.reduce(function (obj, item) {
      if (item.meta.langGen != undefined) {
        obj = [...obj, ...item.meta.langGen];
      }
      return obj;
    }, []);
    const langSpec = to.matched.reduce(function (obj, item) {
      if (item.meta.langSpec != undefined) {
        obj = [...obj, ...item.meta.langSpec];
      }
      return obj;
    }, []);
    const p1 = loadLangAsync(langId, [...new Set(langGen)], null);
    let p2 = oLogin.isLoggedIn ? loadLangAsync(langId, [...new Set(langSpec)], oLogin) : Promise.resolve(true);
    return Promise.all([p1,p2]);
  } else {
    return Promise.resolve(true);
  }
}

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    //https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash) {
      var offset = parseFloat(window.getComputedStyle(document.getElementsByTagName('body')[0]).getPropertyValue('padding-top'));
      return { selector: to.hash, offset: {x: 0, y: offset} }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
})

router.beforeEach(async (to, _, next) => {
  await loadLang(to, store.state.settings.languageId, store.state.oLogin).then(() => next());
});

export default router;
