import { REST, baseLOGIN } from '@/components/RestCall.vue';

export function preauth() {
  return REST.post(baseLOGIN.concat('/access'))
    .then(resp => {
      const data = resp.h['x-uuid'].split('.');
      return {
        uuid: data[0],
        isUser: (data[1] == 'u'),
        isLoggedIn: true
      };
    }).catch(() => {
      return {
        uuid: '',
        isUser: true,
        isLoggedIn: false
      };
    });
}