import Vue from 'vue';
import Vuex from 'vuex';
import alert from '@/components/Alert';

Vue.use(Vuex);

// function builder(data) {
  const setAll = (obj, val) => Object.keys(obj).forEach(k => obj[k] = val);
  const setNull = obj => setAll(obj, null);
  let store = new Vuex.Store({
    state: {
      oLogin: {
        uuid: '',//data.uuid,
        isUser: true,//data.isUser,
        isLoggedIn: false,//data.isLoggedIn
        wasLoggedIn: false,
      },
      oUser: {
        uuid4: '',
        emailAddress: '',
        timezone: null,
        languageId: null,
        visiblePerPage: 10,
        notificationEmail: null,
        notificationPhone: null,
        contactPhone: null,
        formatId: 1
      },
      oLocation: {},
      oAddress: {},
      oCustSeats: {
        uuid4: '',
        forOne: 0,
        forTwo: 0,
        forThree: 0,
        forFour: 0,
        forFive: 0,
        forSix: 0,
        forSeven: 0,
        forEight: 0,
        forNine: 0,
        forTen: 0,
        forMore: 0,
        totalSeats: 0,
        showSeats: true,
        showInSearch: true
      },
      oResNew: {
        customerUuid4: null,
        customerName: null,
        customerCity: null,
        locationCurrency: null,
        resAmount: null,
      },
      oTables: {
        items: [],
        fetchedAt: null,
        isFinished: true,
      },
      oResDetails: null,
      oTraDetails: null,
      oTraRelated: null,
      components: {
        update: {
          traTab: 0,
        },
      },
      iface: {
        successText: '',
        warnText: '',
        message: '',
        width: window.innerWidth,
        iconH: {
          'font-scale': '0.9',
          'shift-v': '4',
        },
        iconT: {
          'font-scale': '0.9',
        },
        iconBS: { // standalone
          'font-scale': '0.95',
          'shift-v': '2',
        },
        iconB: { // text
          'font-scale': '0.95',
          'shift-v': '2',
          class: 'mr-1',
        },
        qrScanner: null,
        showLogin: false,
        showSignup: false,
      },
      settings: {
        timezone: 'Europe/Berlin',
        languageId: 1,
        visiblePerPage: 10,
        bgcTs: null,
        cookieCfg: {n: true, p: false, s: false, m: false, ts: null},
      },
      cookieCheck: false,
    },
    getters: {
      uuid: state => { return state.oLogin.uuid; },
      billUuid: () => { return '00000000-0000-4000-a000-000000000000'; },
      isXs: state => { return state.iface.width < 576; },
      isSm: state => { return state.iface.width >= 576 && state.iface.width < 800; },
      isMd: state => { return state.iface.width >= 800 && state.iface.width < 992; },
      isLg: state => { return state.iface.width >= 992; },
      settings: state => { return state.settings; },
      iH: state => { return state.iface.iconH; },
      iT: state => { return state.iface.iconT; },
      iB: state => { return state.iface.iconB; },
      iBS: state => { return state.iface.iconBS; },
    },
    mutations: {
      login(state, data) {
        state.oLogin = { ...data, wasLoggedIn: true };
      },
      logout(state) {
        state.oLogin.uuid = '';
        state.oLogin.isUser = true;
        state.oLogin.isLoggedIn = false;
        state.oLogin.wasLoggedIn = false;
      },
      showSuccess(state, msg) {
        state.iface.successText = msg;
      },
      showWarn(state, msg) {
        state.iface.warnText = msg;
      },
      showMessage(state, { message, ts, /*title, link*/}) {
        state.iface.message = message;
        this.commit('setBgcTs', ts);
      },
      setWindowWidth(state, msg) {
        state.iface.width = msg;
      },
      initSettings(state) {
        if (localStorage.getItem('oSettings')) {
          let oSett = JSON.parse(localStorage.getItem('oSettings'));
          state.settings = Object.assign({}, state.settings, oSett);
        } else {
          state.cookieCheck = true;
        }
      },
      componentUpdate(state, comp) {
        state.components.update[comp] = state.components.update[comp] + 1; // trigger watchers
      },
      setTimezone(state, tz) {
        state.settings.timezone = tz;
      },
      setLanguage(state, langId) {
        state.settings.languageId = langId;
      },
      setVisiblePerPage(state, num) {
        state.settings.visiblePerPage = num;
      },
      setBgcTs(state, ts) {
        state.settings.bgcTs = ts;
      },
      setCookieCfg(state, cfg) {
        state.settings.cookieCfg = cfg;
      },
      setUser(state, user) {
        state.oUser = { ...user};
      },
      setLocation(state, loc) {
        state.oLocation = { ...loc };
      },
      setAddress(state, loc) {
        state.oAddress = { ...loc };
      },
      setCustSeats(state, custSeats) {
        state.oCustSeats = { ...custSeats };
      },
      setNewRes(state, newRes) {
        state.oResNew = { ...newRes };
      },
      clearNewRes(state) {
        setNull(state.oResNew);
      },
      setResDetails(state, newRes) {
        if (newRes != null) {
          state.oResDetails = { ...newRes };
        } else {
          state.oResDetails = null;
        }
      },
      setTraDetails(state, newTra) {
        if (newTra != null) {
          state.oTraDetails = { ...newTra };
        } else {
          state.oTraDetails = null;
        }
      },
      setTraRelated(state, newRelArr) {
        if (newRelArr != null && newRelArr != []) {
          state.oTraRelated = newRelArr;
        } else {
          state.oTraRelated = [];
        }
      },
      addTables(state, tables) {
        if (state.oTables.items === []) {
          state.oTables.fetchedAt = new Date();
        }
        state.oTables.items.push(...tables);
      },
      finishTables(state, isFinished) {
        state.oTables.isFinished = isFinished;
      },
      clearTables(state) {
        if (state.oTables.isFinished === false) {
          throw 'Let last tableFetch finish first.';
        }
        state.oTables.items = [];
        state.oTables.fetchedAt = null;
        state.oTables.isFinished = true;
      },
      setScanner(state, obj) {
        if ((state.iface.qrScanner === null) || (state.iface.qrScanner !== null && obj === null)) {
          state.iface.qrScanner = obj;
        } else {
          throw 'Destroy scanner first.';
        }
      },
      showLogin(state, val) {
        state.iface.showLogin = val;
      },
      showSignup(state, val) {
        state.iface.showSignup = val;
      },
    },
    actions: {
      setResDetails(context, newRes) {
        context.commit('setResDetails', newRes);
      },
      setTraRelated(context, newTra) {
        context.commit('setTraRelated', newTra);
      },
      setTimezone(context, tz) {
        context.commit('setTimezone', tz);
      },
      setLanguage(context, langId) {
        context.commit('setLanguage', langId);
      },
      setUser(context, user) {
        context.commit('setUser', user);
      },
      initSettings(context) {
        context.commit('initSettings');
      },
      addTables(context, tables) {
        context.commit('addTables', tables);
      },
      finishTables(context, isFinished) {
        context.commit('finishTables', isFinished);
      },
      setScanner(context, obj) {
        context.commit('setScanner', obj);
      },
      showLogin(context, obj) {
        context.commit('showLogin', obj);
      },
      showSignup(context, obj) {
        context.commit('showSignup', obj);
      },
    },
    modules: {
    },
    plugins: [alert],
    strict: process.env.NODE_DEV !== 'production'
  });

  store.subscribe((mutation, state) => {
    if (['setTimezone', 'setLanguage', 'setBgcTs', 'setVisiblePerPage', 'setCookieCfg'].includes(mutation.type)) {
      if (state.settings.cookieCfg.p) {
        updateUserStorage(state.settings);
      } else {
        deleteUserStorage();
      }
    }
  });
  store.subscribeAction((action, state) => {
    if (['setTimezone', 'setLanguage'].includes(action.type)) {
      if (state.settings.cookieCfg.p) {
        updateUserStorage(state.settings);
      } else {
        deleteUserStorage();
      }
    }
  });

//   return store;
// }

function updateUserStorage(obj) {
  localStorage.setItem('oSettings', JSON.stringify(obj));
}
function deleteUserStorage() {
  localStorage.removeItem('oSettings');
}


// export default builder;
export default store;
