<template>
  <div id="app" class="aminwidth">
    <NavTop />
    <b-container class="mt-3 mt-md-4">
      <router-view />
    </b-container>
    <NavBtm />
    <BackgroundCheck />
    <CookieCheck />
  </div>
</template>

<script>
// @ is an alias to /src
import NavTop from '@/components/navigation/NavTop';
import NavBtm from '@/components/navigation/NavBtm';
import BackgroundCheck from '@/components/scripts/BackgroundCheck';
import CookieCheck from '@/components/scripts/CookieCheck';

export default {
  name: 'App',
  created() {
    window.addEventListener('resize', () => {
      this.$store.commit('setWindowWidth', window.innerWidth);
    });
  },
  components: {
    NavTop,
    NavBtm,
    BackgroundCheck,
    CookieCheck,
  },
}
</script>

<style lang="scss">
  @import 'src/plugins/custom';
</style>

<style scoped>
  .aminwidth {
    min-width: 320px;
  }
</style>
