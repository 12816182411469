<script>
import { vm } from '@/main';

function getDate(isPrecise = true, offsetDays = 0) {
  let d = new Date(); // no TZ necessary
  d.setDate(d.getDate() + offsetDays);
  if (isPrecise == false) {
    d.setHours(0, 0, 0, 0);
  }
  return d.toISOString().substring(0, 16).replace('T',' ');
}

function matchTN(qString) {
  const regEx = /{([TN])([+-]\d+)?}/g;
  const result = [];
  let arr = regEx.exec(qString);
  while (arr != null) {
    result.push(arr);
    arr = regEx.exec(qString);
  }
  return result;
}

export default {
  replaceMagic(qObject) {
    // if (qObject.hasOwnProperty('p')) {
    //   vm.$set(qObject, 'p', this.replaceMagicString(qObject.p));
    // }
    // if (qObject.hasOwnProperty('s')) {
    //   vm.$set(qObject, 's', this.replaceMagicString(qObject.s));
    // }
    if (Object.prototype.hasOwnProperty.call(qObject, 'q')) {
      vm.$set(qObject, 'q', this.replaceMagicString(qObject.q));
    }
    return qObject;
  },
  replaceMagicString(qString) {
    let q = qString;
    if (q.includes('{U}') /*&& vm != null*/) { // store should be initialized by now
      let u = vm.$store.getters.uuid;
      u = u.replace(/-/g, '');
      q = q.replace(/{U}/, u);
    }
    if (q.includes('{B}') /*&& vm != null*/) { // store should be initialized by now
      let u = vm.$store.getters.billUuid;
      u = u.replace(/-/g, '');
      q = q.replace(/{B}/, u);
    }

    // in case of like selector (which needs the percent as placeholder)
    q = q.replace(/%/g, encodeURIComponent('%'));

    // [T]oday, [N]ow
    let temp = matchTN(q);
    temp.forEach(el => {
      el[2] = el[2] ?? 0;
      q = q.replace(el[0], encodeURIComponent(getDate(el[1]==='N', parseInt(el[2], 10))));
    });

    return q;
  },
  setFilter(qObject, qArray) {
    if (qObject == null) {
      qObject = {};
    }
    if (qArray == null || qArray.length == 0) {
      return qObject;
    }
    let str = '';
    qArray.forEach(element => {
      if (element.length === 3) {
        str = str.concat(element[0],':',element[1],':');
        let sub = element[2];
        if (element[0].toLowerCase().includes('uuid')) {
          sub = sub.replace(/-/g, '');
        }
        if (element[1] === 's') {
          sub = '%' + sub.trim() + '%';
        }
        str = str.concat(encodeURIComponent(sub),',');
      }
    });

    vm.$set(qObject, 'q', str.substr(0,str.length-1));
    return qObject;
  },
  setSort(qObject, isDesc = false, sortPrim = 'uuid4', sortSec = null) {
    let dir = isDesc ? '-' : '+';
    if (qObject == null) {
      qObject = {};
    }
    vm.$set(qObject, 's', dir.concat(sortPrim));
    if (sortSec != null) {
      vm.$set(qObject, 's', qObject.s.concat(',', dir, sortSec));
    }
    return qObject;
  },
  setPagi(qObject, nextString = null, defLimit = 10) {
    if (qObject == null) {
      qObject = {};
    }
    if (Object.prototype.hasOwnProperty.call(qObject, 'p')) {
      vm.$set(qObject, 'p', '' + qObject.p); // enforce to be sure
      let idx = qObject.p.indexOf(',');
      if (idx != -1) {
        vm.$set(qObject, 'p', qObject.p.substr(0, idx));
      }
    } else {
      vm.$set(qObject, 'p', String(defLimit));
    }
    if (nextString != null && nextString != '') {
      vm.$set(qObject, 'p', qObject.p.concat(',', nextString));
    }
    return qObject;
  },
  fetchSort(qObject, defIsDesc = false, defSort = 'uuid4')  {
    let hasStringInObject = false;
    let str = defSort;
    let order = defIsDesc;

    if (qObject != null && Object.prototype.hasOwnProperty.call(qObject, 's')) {
      str = qObject.s;
      hasStringInObject = true;
    }
    if (hasStringInObject) {
      order = (str.substr(0,1) === '-');
      str = str.substr(1);
    }
    
    let idx = str.indexOf(',');
    if (idx === -1) {
      return [order, str, null];
    } else {
      return [order, str.substr(0,idx), str.substr(idx+2)];
    }
  }
};
</script>
